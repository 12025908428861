/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/function-component-definition */
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import tw, { css, styled } from "twin.macro"; // eslint-disable-line no-unused-vars
// import { MdBolt } from "react-icons/md"
import { differenceInCalendarDays, startOfDay } from "date-fns";

import {
  ContentWithUtilsBar,
  LoadingTile,
  TilePrim,
} from "../../components/util-components";
import { PageTitleContext } from "../../utils/pagetitle-provider";
import withRoles from "../../utils/with-roles";
import { timeFilterObjFutureDayjs } from "../../utils/global-consts";
import JimHelmet from "../../components/JimHelmet";
import UtilsBar from "../../layout/UtilsBar";
import JobTile from "../../components/page-components/jobs-find/JobTile";

const JOBINSTANCES_QUERY = gql`
  query FindOpenOrSwapableJobInstances(
    $startsAfter: Float
    $endsBefore: Float
    $pool: [ID]
    $status: [String]
  ) {
    listJobInstances(
      startsAfter: $startsAfter
      endsBefore: $endsBefore
      pool: $pool
      status: $status
    ) {
      id
      job {
        id
        title
      }
      status
      department {
        id
        name
        facility {
          name
        }
      }
      start_time
      end_time
      length
      pay
      description
      worker {
        id
        first_name
        last_name
      }
      instant_book
      group_id
    }
  }
`;

const GET_USER_INFO = gql`
  query GetUserInfo($startsAfter: Float) {
    getUser {
      availabilities(startsAfter: $startsAfter) {
        id
        start_time
        end_time
      }
      accepted_jobs(startsAfter: $startsAfter) {
        id
        start_time
        end_time
      }
      pools {
        id
        title
      }
    }
  }
`;

const JobsFinder = () => {
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Einsätze finden"), []);
  // const { userID } = useContext(UserRolesContext)
  const [timesSel, setTimesSel] = useState(timeFilterObjFutureDayjs);
  // const [timeFilter, setTimeFilter] = useState({
  //   after: new Date(),
  //   before: addMonths(new Date(), 1),
  // })
  // const [poolSelected, setPoolSelected] = useState("1")
  const { data: userInfoData, loading: userInfoLoading } = useQuery(
    GET_USER_INFO,
    {
      variables: { startsAfter: startOfDay(new Date()).getTime() },
    }
  );
  const [jobInstsByGroupID, setJobInstsByGroupID] = useState({});
  const [findOpenJobInstances, { data: jobInstData, loading: jobInstLoading }] =
    useLazyQuery(JOBINSTANCES_QUERY);
  const [jobsWithUserCounter, setJobsWithUserCounter] = useState(0);
  const [noJobsAvail, setNoJobsAvail] = useState(false);

  useEffect(() => {
    setJobsWithUserCounter(0);
    if (userInfoData) {
      findOpenJobInstances({
        variables: {
          startsAfter: timesSel.left.valueOf(),
          endsBefore: timesSel.right.valueOf(),
          status: ["OPEN", "ASSIGNED_SWAP_REQ"],
          pool: userInfoData.getUser.pools.map(pool => pool.id),
        },
      });
    }
  }, [userInfoData, timesSel]);

  useEffect(() => {
    setJobsWithUserCounter(0);
    const jobInstObj = {};
    if (jobInstData && jobInstData.listJobInstances[0]) {
      jobInstData.listJobInstances.forEach(jobInst => {
        if (!jobInstObj[jobInst.group_id]) {
          jobInstObj[jobInst.group_id] = jobInst;
        }
      });
    }
    setJobInstsByGroupID(jobInstObj);
  }, [jobInstData]);

  const activateJobsWithUserCounter = () => {
    if (
      jobInstData &&
      jobsWithUserCounter + 1 >= Object.keys(jobInstsByGroupID).length
    ) {
      setNoJobsAvail(true);
    }
    setJobsWithUserCounter(jobsWithUserCounter + 1);
  };

  return (
    <>
      <JimHelmet path="jobs/search" />
      <UtilsBar showTime setTimesSel={setTimesSel} />
      <ContentWithUtilsBar>
        {Object.keys(jobInstsByGroupID)[0] &&
          Object.keys(jobInstsByGroupID).map((groupID, ind) => (
            <JobTile
              jobInst={jobInstsByGroupID[groupID]}
              withDate={
                ind === 0 ||
                (ind > 0 &&
                  differenceInCalendarDays(
                    new Date(jobInstsByGroupID[groupID].start_time),
                    new Date(
                      jobInstsByGroupID[
                        Object.keys(jobInstsByGroupID)[ind - 1]
                      ].start_time
                    )
                  ) > 0)
              }
              userJobsArr={
                userInfoData ? userInfoData.getUser.accepted_jobs : []
              }
              userAvailsArr={
                userInfoData ? userInfoData.getUser.availabilities : []
              }
              refetchVars={{
                startsAfter: timesSel.left.valueOf(),
                endsBefore: timesSel.right.valueOf(),
              }}
              activateJobsWithUserCounter={activateJobsWithUserCounter}
            />
          ))}
        {(noJobsAvail ||
          (!jobInstLoading &&
            jobInstData &&
            !jobInstData.listJobInstances[0])) && (
          <TilePrim>Aktuell sind keine Einsätze verfügbar.</TilePrim>
        )}
        {(jobInstLoading || userInfoLoading) && <LoadingTile />}
      </ContentWithUtilsBar>
    </>
  );
};

export default withRoles(JobsFinder, "WORKER");

/*
jobInstData.listJobInstances.map((jobInst, ind) => (
          <JobTile
            jobInst={jobInst}
            withDate={
              ind === 0 ||
              (ind > 0 &&
                differenceInCalendarDays(
                  new Date(jobInst.start_time),
                  new Date(jobInstData.listJobInstances[ind - 1].start_time)
                ) > 0)
            }
            sameAsAbove={
              ind > 0 &&
              jobInst.start_time ===
                jobInstData.listJobInstances[ind - 1].start_time &&
              jobInst.end_time ===
                jobInstData.listJobInstances[ind - 1].end_time &&
              jobInst.job.id === jobInstData.listJobInstances[ind - 1].job.id
            }
            userJobsArr={userInfoData ? userInfoData.getUser.accepted_jobs : []}
            userAvailsArr={
              userInfoData ? userInfoData.getUser.availabilities : []
            }
          />
        ))
*/
